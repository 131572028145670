import React from "react"
import PropTypes from 'prop-types'

const RepeatableContent = ({ content }) => {
  return (
    <div id="repeatable-content">
      {content.map(item => {
        return (
          <div className="repeatable-content" key={item.title}>
            <h2>{item.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: item.copy }} />
          </div>
        )
      })}
    </div>
  )
}

RepeatableContent.propTypes = {
  content: PropTypes.array.isRequired
}

export default RepeatableContent

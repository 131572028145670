import React from 'react'
import { getUniqueContinents } from '../../helper'
import * as style from './workdetailmeta.module.scss'

const WorkDetailMeta = project => {
  const offices = project.ProjectData.offices
  const client = project.ProjectData.client ? project.ProjectData.client[0] : undefined

  return (
    <ul className={style.wrap}>
      {client && (
        <li>
          <span>Klient</span>
          <strong>{client.title}</strong>
        </li>
      )}
      {offices && (
      <li>
        <span>Rynek</span>
        <strong>{getUniqueContinents(offices).join(', ')}</strong>
      </li>
      )}
      {offices && (
      <li>
        <span>Biuro</span>
        <strong>{offices.map(office => office.title).join(', ')}</strong>
      </li>
      )}
    </ul>
  )
}

export default WorkDetailMeta

import React from "react"
import PropTypes from 'prop-types'
import * as style from './stats.module.scss'

const StatsItem = ({ stat, suffix, copy }) => {
  return (
    <div className={style.item}>
      <strong>
        <span className={style.stat}>{stat}</span>
        { suffix && (
          <span className={style.suffix}>{suffix}</span>
        )}
      </strong>
      { copy && (
      <p className={style.copy}>{copy}</p>
      )}
    </div>
  )
}
StatsItem.propTypes = {
  stat: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  copy: PropTypes.string
}

const Stats = ({ stats }) => {
  return (
    <div className={style.wrap}>
      {stats.map((stat, i) => {
        return <StatsItem key={i} {...stat} />
      })}
    </div>
  )
}

Stats.propTypes = {
  stats: PropTypes.array.isRequired
}

export default Stats

// TODO: remove
// ['Europe', 'Asia', ...]
export const getUniqueContinents = (offices) => {
  if (!offices) { return [] }
  const continents = offices.map(office => office.OfficeData.continent)
  return continents.filter((continent, i) => continents.indexOf(continent) === i)
}

// {'Europe': [{title: 'London', ...}], 'Asia: [{...}, {...}]}
export const getOfficesByMarket = (offices) => {
  return offices.reduce((grouped, office) => {
    const market = office.OfficeData.market.name
    if (!grouped[market]) {
      grouped[market] = []
    }
    grouped[market].push(office)
    return grouped
  }, {})
}

// https://www.gatsbyjs.com/docs/debugging-html-builds/
export const isMobile = () => {
  if (typeof window !== `undefined`) {
    return window.matchMedia(`(max-width: 767px)`).matches
  }
  return false
}

// Markets may be derived from projects or clients or mentions/news
export const getMarkets = elements => {
  let type = null
  if (elements[0].ProjectData) {
    type = 'ProjectData'
  }
  else if (elements[0].ClientData) {
    type = 'ClientData'
  }
  else if (elements[0].MentionData) {
    type = 'MentionData'
  }

  let data = elements
    .filter(element => element[type].market)
    .sort((a, b) => {
      if (a[type].market.databaseId < b[type].market.databaseId) return -1
      else if (a[type].market.databaseId > b[type].market.databaseId) return 1
      return 0
    })
    .reduce((markets, element) => {
      const name = element[type].market.name
      if (!markets.includes(name)) {
        markets.push(name)
      }
      return markets
    }, [])
    .map(element => {
      return {
        value: element,
        label: element
      }
    })

  data.unshift({value: '', label: 'Wszystkie rynki'})
  return data
}

// Categories may be derived from projects or mentions/news
export const getCategories = elements => {
  let type = null
  let dbname = null
  if (elements[0].ProjectData) {
    type = 'ProjectData'
    dbname = 'category'
  }
  else if (elements[0].MentionData) {
    type = 'MentionData'
    dbname = 'type'
  }

  let data = elements
    .reduce((categories, element) => {
      const category = element[type][dbname]
      if (!categories.includes(category)) {
        categories.push(category)
      }
      return categories
    }, [])
    .sort(function (a, b) {
      if (a < b) return -1
      else if (a > b) return 1
      return 0
    })
    .map(element => {
      return {
        value: element,
        label: element
      }
    })

  data.unshift({value: '', label: 'Wszystkie kategorie'})
  return data
}

import React from "react"
import PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";
import YouTube from 'react-youtube';
import ConditionalWrapper from '../ConditionalWrapper'
import * as style from './workhero.module.scss'

const WorkHero = ({ heroTitle, heroDescription, heroVideo, heroImage, sidekickImage }) => {

  let sideKickImageStyles = {}

  if(!heroVideo) {
    sideKickImageStyles = {"marginTop": 0}
  }


  return (
    <section id={style.workHero}>
      { heroVideo && (
        <div className={style.videoWrapWrap}>
          <YouTube videoId={heroVideo} containerClassName="video-wrap" />
        </div>
      )}
      { heroImage && (
        <div className={style.heroImage}>
          <GatsbyImage image={heroImage.localFile.childImageSharp.gatsbyImageData} />
        </div>
      )}


      <ConditionalWrapper condition={sidekickImage || heroTitle || heroDescription} wrapper={children => <div className={style.extras}>{children}</div>}>
        { sidekickImage && (
          <div className={style.sidekickImage} >
            <GatsbyImage style={sideKickImageStyles}
              image={sidekickImage.localFile.childImageSharp.gatsbyImageData}
              alt={sidekickImage.altText}
              title={sidekickImage.title} />
          </div>
        )}

        <ConditionalWrapper condition={heroTitle || heroDescription} wrapper={children => <div className={style.textWrap}>{children}</div>}>
          { heroTitle && (
            <strong>{heroTitle}</strong>
          )}
          { heroDescription && (
            <p>{heroDescription}</p>
          )}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </section>
  );
}

WorkHero.propTypes = {
  heroTitle: PropTypes.string,
  heroDescription: PropTypes.string,
  heroVideo: PropTypes.string,
  heroImage: PropTypes.object,
  sidekickImage: PropTypes.object
}

export default WorkHero

import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Description from "../components/description"
import Stats from "../components/stats"
import RepeatableContent from "../components/repeatableContent"
import WorkDetailMeta from "../components/work/workDetailMeta"
import WorkHero from "../components/work/workHero"
import WorkNextPrev from "../components/work/workNextPrev"
import "../styles/page-workDetail.scss"

const WorkDetail = ({ data }) => {
  const page = data.wpProject

  let schema = {}
  if (page.ProjectHero.heroVideo) {
    schema = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": page.title,
      "description": page.SeoMeta.seoDescription,
      "thumbnailUrl": "https://i.ytimg.com/vi/" + page.ProjectHero.heroVideo + "/hqdefault.jpg",
      "uploadDate": page.date
    }
  }

  return (
    <Layout id="work-detail" darkMode={page.Header.darkMode}>
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
        schema={schema}
      />

      <GatsbyImage
        image={page.Header.headerImage.localFile.childImageSharp.gatsbyImageData}
        alt={page.Header.headerImage.altText}
        title={page.Header.headerImage.title}
        style={{ height: '60vw', maxHeight: '500px'}} />

      <div className="page-dark">
        <section className="detail-header">
          <div className="detail-header-main">
            <a href="/work" className="go-back">&larr; Powrót do Case Studies</a>
            <h1>{page.title}</h1>
            <Description {...page.Description} />
          </div>
          <div className="detail-header-aux">
            <WorkDetailMeta {...page} />
          </div>
        </section>

        <WorkHero {...page.ProjectHero} />
      </div>

      <div className="work-detail-container">
        {page.ProjectData.pullQuote && (
          <blockquote>{page.ProjectData.pullQuote}</blockquote>
        )}

        {page.RepeatableContent.content && (
          <RepeatableContent {...page.RepeatableContent} />
        )}
      </div>

      {page.Stats.stats && (
        <Stats {...page.Stats} />
      )}

      <WorkNextPrev title={page.title} />
    </Layout>
  );
}

export default WorkDetail

export const query = graphql`query ($slug: String) {
  wpProject(slug: {eq: $slug}) {
    title
    link
    date
    Header {
      darkMode
      headerImage {
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(height: 640, layout: FULL_WIDTH)
          }
        }
      }
    }
    Description {
      description
    }
    ProjectData {
      pullQuote
      client {
        ... on WpClient {
          title
        }
      }
      offices {
        ... on WpOffice {
          title
          OfficeData {
            continent
          }
        }
      }
    }
    ProjectHero {
      heroTitle
      heroDescription
      heroVideo
      heroImage {
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(height: 960, layout: FULL_WIDTH)
          }
        }
      }
      sidekickImage {
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(height: 640, layout: FULL_WIDTH)
          }
        }
      }
    }
    RepeatableContent {
      content {
        title
        copy
      }
    }
    Stats {
      stats {
        stat
        suffix
        copy
      }
    }
    SeoMeta {
      seoTitle
      seoDescription
    }
  }
}
`

import React from "react"
import PropTypes from 'prop-types'
import { Link, graphql, useStaticQuery } from 'gatsby'
import * as style from './worknextprev.module.scss'

const WorkNextPrev = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpProject(sort: {order: DESC, fields: date}) {
        nodes {
          title
          link
          ProjectData {
            client {
              ... on WpClient {
                title
              }
            }
          }
        }
      }
    }
  `)

  // Calculate next and prev Projects
  const projects = data.allWpProject.nodes
  const index = projects.findIndex(project => project.title === title)
  let next = index + 1
  if (next >= projects.length) next = 0
  let prev = index - 1
  if (prev < 0) prev = projects.length - 1

  return (
    <section className={style.wrap}>
      <Link className={style.prev} to={projects[prev].link}>
        { projects[prev].ProjectData.client && (
          <div className={style.client}>{projects[prev].ProjectData.client[0].title}</div>
        )}
        <strong>{projects[prev].title}</strong>
      </Link>
      <Link className={style.next} to={projects[next].link}>
        { projects[next].ProjectData.client && (
          <div className={style.client}>{projects[next].ProjectData.client[0].title}</div>
        )}
        <strong>{projects[next].title}</strong>
      </Link>
    </section>
  )
}

WorkNextPrev.propTypes = {
  title: PropTypes.string.isRequired
}

export default WorkNextPrev
